/* eslint-disable react/require-default-props */
import React from 'react';
import { S3_ROOT } from '../../api/config';
import icon from '../../assets/images/icon.svg';
import PropTypes from 'prop-types';

const Image = (props) => {
	const { img, imgUrl, className, onClick } = props;
	return (
		<img
			src={imgUrl ? S3_ROOT + imgUrl : img || icon}
			alt='...'
			className={className || ''}
			onClick={onClick}
		/>
	);
};

Image.propTypes = {
	img: PropTypes.string,
	imgUrl: PropTypes.string,
	className: PropTypes.string,
	onClick: PropTypes.func
};

export default Image;
