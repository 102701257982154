import { convertObjectToUrlparams } from '../Services/UtilService';
import { API_ROOT } from './config';
import lang from 'i18next';

/* todo: Refactor this after URL constants */
function sanitizeUrl(url, data = {}) {
	const noNeedLangUrl = ['addCustomImageUrl'];
	if (noNeedLangUrl.indexOf(url) === -1) {
		data.languageCode = lang.resolvedLanguage || 'tr';
	}
	return url + convertObjectToUrlparams(data);
}

export function parseError(error) {
	return error || 'Something went wrong';
}

export default function request(url, options = {}, skipAuthToken) {
	const config = {
		method: 'GET',
		...options
	};
	const errors = [];

	if (!url) {
		errors.push('url');
	}

	if (!config.payload && config.method !== 'GET' && config.method !== 'DELETE') {
		errors.push('payload');
	}

	if (errors.length) {
		throw new Error(`Error! You must pass \`${errors.join('`, `')}\``);
	}
	const headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
		...(!skipAuthToken && {
			Authorization: `Bearer ${localStorage.getItem('token')}`
		}),
		...(config.formData && { 'Content-Type': 'multipart/form-data' }),
		...config.headers
	};

	const params = {
		headers,
		method: config.method
	};

	if (params.method !== 'GET') {
		if (config.formData) params.body = config.payload;
		else params.body = JSON.stringify(config.payload);
	}

	// eslint-disable-next-line react-hooks/rules-of-hooks

	return fetch(`${API_ROOT}${sanitizeUrl(url, config.urlData)}`, params).then(async (response) => {
		if (response.status > 299) {
			const contentType = response.headers.get('content-type');
			switch (response.status) {
				case 401:
				case 403:
					// cogoToast.warn("Authentication Error!", {
					//   position: "top-right",
					//   heading: "Error!",
					// });
					setTimeout(() => {
						if (window.location.href.indexOf('/login') === -1) {
							localStorage.clear();
							localStorage.setItem('reditectUrl', window.location.href);
							window.location.href = '/#/auth/login';
						}
					}, 1000);
					break;
				case 405:
					// cogoToast.warn("Operation is not permitted");
					break;
				case 422:
					// cogoToast.warn(
					//   response.message ? response.message : "Cannot perform action"
					// );
					break;
				case 503:
					// cogoToast.warn("Oops... Something went wrong.");
					break;
				default:
					break;
			}
			if (response.status === 404) {
				return { status: response.status };
			}

			if (response.status === 400) {
				const responseJson = await response.json();
				return {
					response: responseJson,
					status: response.status,
					error: true
				};
			}
			const error = { status: response.statusText };

			if (contentType && contentType.includes('application/json')) {
				error.response = {
					status: response.status,
					data: await response.json()
				};
			} else {
				error.response = {
					status: response.status,
					data: await response.text()
				};
			}

			throw error;
		} else {
			const contentType = response.headers.get('content-type');
			if (contentType && contentType.includes('application/json')) {
				if (config.toast) {
					// cogoToast(config.toast);
				}
				return response.json();
			}

			return response.text();
		}
	});
}
