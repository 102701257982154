import React, { Fragment, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../assets/css/Footer.css';
import { Link } from 'react-router-dom';
import { PRODUCTS_CATEGORY_GET } from '../api/ApiConstants';
import whitelogo from '../assets/images/white-logo.svg';
import one from '../assets/images/one.png';
import two from '../assets/images/two.png';
import Payment from '../assets/images/payment.png';
import three from '../assets/images/45.png';
import threes from '../assets/images/46.png';
import Translator from './Translator';
import request from '../api/request';

const Footer = () => {
	const [catList, setCatList] = useState([]);

	const getAllProductTypes = () => {
		request(PRODUCTS_CATEGORY_GET, {}, true).then((res) => {
			if (res && res.data) {
				setCatList(res.data || []);
			}
		});
	};

	useEffect(() => {
		getAllProductTypes();
	}, []);

	return (
		<Fragment>
			<Container
				fluid
				className='footer'>
				<Row>
					<Col
						md={2}
						xs={6}>
						<Link to='/'>
							{' '}
							<img
								src={whitelogo}
								width='70%'
								className='foot-logo'
								alt='...'
							/>
						</Link>
						<h6 style={{ marginLeft: '20px' }}>
							<Translator textKey='Header.followUs' />
						</h6>
						<a
							href='https://www.facebook.com/dotsco.co'
							target='_blank' rel="noreferrer">
							<i className='fab fa-facebook-f'></i>
						</a>
						<a
							href='https://www.instagram.com/dotsco.co/'
							target='_blank' rel="noreferrer">
							<i className='fab fa-instagram'></i>
						</a>
						<a
							href='https://twitter.com/DotscoTech'
							target='_blank' rel="noreferrer">
							<i className='fab fa-twitter'></i>
						</a>
						<a
							href='https://www.snapchat.com/add/dotsco.co'
							target='_blank' rel="noreferrer">
							<i className='fab fa-snapchat'></i>
						</a>
						<a
							href='https://www.tiktok.com/@dotsco.co'
							target='_blank' rel="noreferrer">
							<i className='fab fa-tiktok'></i>
						</a>
						<h6 style={{ marginTop: '15px', marginLeft: '7px' }}>
							<Translator textKey='Header.new1' />
						</h6>
						<a
							href=' https://ty.gl/r5jaoqd'
							target='_blank'>
							{' '}
							<img
								style={{ marginLeft: '20px' }}
								src={three}
								alt='..'
								className='store-imgs'
							/>
						</a>
						<a
							href='https://www.hepsiburada.com/magaza/dotsco'
							target='_blank' rel="noreferrer">
							<img
								src={threes}
								alt='..'
								className='store-img'
							/>
						</a>
					</Col>
					<Col
						md={2}
						xs={6}
						style={{ marginTop: '35px' }}>
						{/* <h5><Translator textKey="Header.shop" /></h5> */}
						<ul>
							{catList &&
								catList.map((cat, i) => {
									if (i >= 5) return null;
									return (
										<Link
											to={'/shop/' + cat.categoryId}
											key={i}>
											<li>{cat.categoryName}</li>
										</Link>
									);
								})}
						</ul>
					</Col>
					<Col
						md={4}
						xs={6}
						style={{ marginTop: '35px' }}>
						{/* <h5><Translator textKey="Header.company" /></h5> */}
						<Row>
							<Col md={6}>
								<ul>
									<Link to='/whatisdots'>
										<li>
											<Translator textKey='Header.what' />
										</li>
									</Link>
									<Link to='/aboutus'>
										<li>
											<Translator textKey='Header.aboutus' />
										</li>
									</Link>
									<Link to='/reseller'>
										<li>
											<Translator textKey='Header.reseller' />
										</li>
									</Link>
									<Link to='/ambassador'>
										<li>
											<Translator textKey='Header.ambassador' />
										</li>
									</Link>
									<Link to='/contact'>
										<li>
											<Translator textKey='Header.con' />
										</li>
									</Link>

									{/* <Link to="/help"><li><Translator textKey="Header.help" /></li></Link>
                     <Link to="/enterprise"><li><Translator textKey="Header.dotsEnterprise" /></li></Link>
                     <Link to="/reseller"><li><Translator textKey="Header.reseller" /></li></Link>
                     <Link to="/ambassador"><li><Translator textKey="Header.ambassadors" /></li></Link>
                     <Link to="/terms"><li><Translator textKey="Header.termsUse" /></li></Link> */}
								</ul>
							</Col>
							<Col md={6}>
								<ul>
									<Link to='/help'>
										<li>
											<Translator textKey='Header.help' />
										</li>
									</Link>
									<Link to='/privacy'>
										<li>
											<Translator textKey='Header.privacyPolicy' />
										</li>
									</Link>
									<Link to='/deliverys'>
										<li>
											<Translator textKey='Delivery.one' />
										</li>
									</Link>
									<Link to='/distance'>
										<li>
											<Translator textKey='Header.distance' />
										</li>
									</Link>
									<Link to='/terms'>
										<li>
											<Translator textKey='Header.termsUse' />
										</li>
									</Link>
								</ul>
							</Col>
						</Row>
					</Col>
					<Col
						md={4}
						xs={6}
						className='footer-payment'>
						<h5>
							<Translator textKey='Header.downloadApp' />
						</h5>
						<center>
							<a
								href='https://play.google.com/store/apps/details?id=com.infinitech.dots'
								target='_blank' rel="noreferrer">
								<img
									src={one}
									alt='...'
									width='35%'
								/>
							</a>
						</center>
						<br />
						<center>
							{' '}
							<a
								href='https://apps.apple.com/in/app/dots-tap-my-dots/id1599017164'
								target='_blank' rel="noreferrer">
								<img
									src={two}
									alt='...'
									width='35%'
									className='apple'
								/>
							</a>
						</center>
						<center>
							<img
								src={Payment}
								alt='..'
								width='70%'
								className='pays'
							/>
						</center>
					</Col>
				</Row>
			</Container>
		</Fragment>
	);
};

export default Footer;
