const env = process.env.REACT_APP_ENVIRONMENT ?? 'production';
if (env !== 'production') {
	console.log(env);
}
const production = {
	ENV: 'production',
	OB_ROLE: 'admin',
	HOST: 'https://api.dotsco.co/',
	S3: 'https://dotsco.s3.me-south-1.amazonaws.com/',
	CUSTOM_DOTS_ID: 14
};

const stage = {
	ENV: 'staging',
	OB_ROLE: 'admin',
	HOST: 'https://stage.api.dotsco.co/',
	S3: 'https://dotsco.s3.me-south-1.amazonaws.com/',
	CUSTOM_DOTS_ID: 5
};

const development = {
	ENV: 'development',
	OB_ROLE: 'admin',
	HOST: 'http://localhost:3000/',
	S3: 'https://dotsco.s3.me-south-1.amazonaws.com/',
	CUSTOM_DOTS_ID: 5
};

const config = {
	development,
	production,
	stage
};

export default config[env];

export const API_ROOT = `${config[env].HOST}`;
export const S3_ROOT = `${config[env].S3}`;
export const CUSTOM_DOTS_ID = config[env].CUSTOM_DOTS_ID;
