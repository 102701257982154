export const PRODUCTS_GET = 'public/getProductList';
export const PRODUCTS_GET_ALL = 'public/getAllProducts';
export const PRODUCTS_GET_BY_ID = 'public/getProductById/:id';
export const PRODUCTS_RATING_BY_ID = 'public/viewUserRatingListByProductId/newUI/:id';
export const PRODUCTS_CATEGORY_GET = 'public/viewProductCategoryList';
export const ADD_PRODUCT_IMAGE = 'addCustomImageUrl';

export const ADD_TO_CART = 'addToCart';
export const CART_UPDATE = 'updateQuantityInCart';
export const REMOVE_CART_ITEM = 'removeAnItemFromCart';

export const ORDER_CREATE = 'createOrder/:id';
export const ORDER_CANCEL = 'cancelUserOrder/:id';
export const ORDER_VIEW_ALL = 'viewAllCurrentUserOrders';
export const ORDER_RATING = 'addUserRating/newUI';
export const ORDER_PAYMENT = 'payment/:id?orderType={1}';

export const TUTORIAL_VIDEOS_HOME = 'public/getAllTutorialsByType/newUI/1';
export const TUTORIAL_VIDEOS_HELP = 'public/getAllTutorialsByType/newUI/0';

export const USER_CREATE = 'userSignup';
export const USER_LOGIN = 'userLogin';
export const REQUEST_OTP = 'requestOtp';
export const REQUEST_OTP_REGISTER = 'requestOtpForMobile';
export const VERIFY_OTP = 'verifyOtp';
export const EMAIL_AVAILABILITY = 'public/checkEmailAvailability';
export const RESET_PASSWORD = 'public/setUserPassword/newUI';
export const FORGOT_PASSWORD = 'public/newUi/forgotPassword';
export const CHANGE_PASSWORD = 'changePassword';
export const CHECK_USERNAME_AVAILABILITY = 'public/checkUserNameAvailability/:id';
export const CHECK_EMAIL_AVAILABILITY = 'public/checkEmailAvailability';

export const USER_PROFILE_GET = 'getuserProfileById';
export const USER_PROFILE_UPDATE = 'updateUserProfile';
export const USER_LANGUAGE_CHANGE = 'changeLanguage/:id';

export const ADDRES_CREATE = 'createAddress';
export const ADDRES_UPDATE = 'updateAddress';
export const ADDRES_GET = 'viewAddress';
export const ADDRES_DELETE = 'deleteAddress/:id';

export const COUNTRY_LIST = 'public/countryList';
export const STATE_LIST = 'public/stateList';

export const RATING_ADD = 'addUserRating/newUI';
export const RATING_GET = 'public/viewUserRatingListByProductId/newUI/:id';

export const AMBASSADOR_POST = 'public/addAmbassadorContactDetails';
export const ENTERPRISE_POST = 'public/addEnterpriseDetails';
export const RESELLERS_POST = 'public/addResellerDetails';

export const PRODUCT_IMG_UPLOAD = 'public/upload/product-img';

export const GET_META_DATA = 'public/getAllMetadata';
// export const GET_META_DATA = "public/getMetaDataForAKey";

export const GET_BANNER_DATA = 'public/getAllBanners';
