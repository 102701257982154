import React, { Fragment, useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import language from 'i18next';
import request from '../../api/request';
import { USER_LANGUAGE_CHANGE } from '../../api/ApiConstants';

const LanguageSwitch = () => {
	// const [popoverOpen, setPopoverOpen] = useState(false);
	const [currentLang, setCurrentLang] = useState('TR');

	// const toggle = () => setPopoverOpen(!popoverOpen);

	LanguageSwitch.changeLanguage = (lang) => {
		console.log('lang', lang);
		if (currentLang !== lang) {
			if (localStorage.getItem('token')) {
				request(USER_LANGUAGE_CHANGE.replace(':id', lang === 'EN' ? 1 : 2), { method: 'POST', payload: {} }).then(
					() => {
						language.changeLanguage(lang);
						setCurrentLang(lang);
						window.location.reload();
					}
				);
			} else {
				language.changeLanguage(lang);
				setCurrentLang(lang);
				window.location.reload();
			}
		}
	};

	LanguageSwitch.changeToEnglish = () => LanguageSwitch.changeLanguage('EN');
	LanguageSwitch.changeToTurkish = () => LanguageSwitch.changeLanguage('TR');

	useEffect(() => {
		const temp = language.resolvedLanguage?.toUpperCase() || 'TR';
		setCurrentLang(temp);
	}, []);

	return (
		<Fragment>
			{/* <span className="lang-icon pointer" id="langPopover" onMouseEnter={() => setPopoverOpen(true)} onMouseLeave={() => setPopoverOpen(false)}>

        </span>
        <UncontrolledPopover placement="bottom" trigger="legacy" isOpen={popoverOpen} target="langPopover" toggle={toggle} onMouseEnter={() => setPopoverOpen(true)} onMouseLeave={() => setPopoverOpen(false)}>

            <h6 className="lang-name">  <Translator textKey="Header.selectLanguage" /></h6>
            <ul className="pointer">
                <li onClick={() => changeLanguage("EN")} className={currentLang === "EN" ? "actives" : ""}><Translator textKey="Language.EN" />
                    {currentLang === "EN" && <i className="fas fa-check-circle mt-2"></i>}
                </li>
                <li onClick={() => changeLanguage("TR")} className={currentLang === "TR" ? "actives" : ""}><Translator textKey="Language.TR" />
                    {currentLang === "TR" && <i className="fas fa-check-circle mt-2" />}
                </li>
            </ul>
        </UncontrolledPopover> */}
			<Dropdown>
				<Dropdown.Toggle id='dropdown-basic'>{currentLang}</Dropdown.Toggle>
				<Dropdown.Menu>
					<Dropdown.Item
						onClick={LanguageSwitch.changeToEnglish}
						className={currentLang === 'EN' ? 'actives' : ''}>
						EN
					</Dropdown.Item>
					<Dropdown.Item
						onClick={LanguageSwitch.changeToTurkish}
						className={currentLang === 'TR' ? 'actives' : ''}>
						TR
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</Fragment>
	);
};

export default LanguageSwitch;
