import moment from 'moment';

export const formatNumberInput = (value) => {
	const splitValues = value.toString().split('.');
	if (parseFloat(value) < 0) value = '0';
	if (splitValues[1] && splitValues[1].length > 3) value = parseFloat(value).toFixed(3);
	return value;
};

export const displayCurrency = (val = 0) => {
	return '₺ ' + (parseFloat(val) || 0).toFixed(2);
};

export const convertDateFormat = (date) => {
	const d = new Date(date);
	// const utc = d.getTime() + (d.getTimezoneOffset() * 60000);
	// const uaeDate = new Date(utc + (3600000*4));
	return date ? moment(d).format('DD MMM YYYY hh:mm A') : 'NA';
};

export const convertDateOnlyFormat = (date) => {
	const d = new Date(date);
	return date ? moment(d).format('DD MMM YYYY') : 'NA';
};

export const convertObjectToUrlparams = (data) => {
	let returnValue = '?';
	if (data) {
		Object.keys(data).forEach((key) => {
			let value = data[key];
			if ((key && value && value !== 'Invalid date') || (key === 'searchKey' && value && value.length > 2)) {
				if ((key === 'startDate' && moment(value).isValid()) || (key === 'endDate' && moment(value).isValid())) {
					value = moment(value).format('MM/DD/YYYY');
				}
				returnValue = returnValue + key + '=' + value + '&';
			}
		});
	}
	returnValue = returnValue.slice(0, returnValue.length - 1);
	return returnValue;
};

export const getUrlSearchParams = (query) => {
	// const query = new URLSearchParams(p);
	const data = Object.fromEntries([...query]);

	// for (let entry of query.entries()) {
	//     const [param, value] = entry;
	//     data[param] = value;
	// }
	return data;
};

export const checkNameValidation = (val) => {
	const pattern = /[^a-zA-Z_ğüşöçıİĞÜŞÖÇ ]/i;
	return val && pattern.test(val);
};
